import { createStore } from 'vuex'

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	modules[moduleName] = modulesFiles(modulePath).default
	return modules
}, {})

export default createStore({
  state: {
        token:localStorage.getItem('hltoken') ? localStorage.getItem('hltoken'):'',
		user:{},
		zhanghu:{},
		isbind: localStorage.getItem('isbind') ? localStorage.getItem('isbind'):'',
		userinfo: localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')) :'',
  },
  mutations: {
    setToken(state, value) { // 设置存储token
		state.token = value;
		localStorage.setItem('hltoken', value);
	},
	setIsbind(state, value) { // 设置存储isbind
		state.isbind = value;
		localStorage.setItem('isbind', value);
	},
	setUserinfo(state, value) { // 设置存储userinfo
		state.userinfo = JSON.parse(value) ;
		localStorage.setItem('userinfo', value);
	},
},
  actions: {
  },
  modules
})
