import request from '../request.js'

export default {
    serviceRequest(_data){
        return request({
            url: '/user/order/serviceRequest?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    serviceRerviceEsRequest(_data){
        return request({
            url: '/user/order/serviceEsRequest?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    serviceCount(_data){
        return request({
            url: '/user/order/serviceCount?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    getOrderCsiListDto(_data){
        return request({
            url: '/user/order/getOrderCsiListDto?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    checkServiceRequest(_data){
        return request({
            url: '/user/order/checkServiceRequest?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    exportOrder(_data) {
        //导出回复模版
        return request({
            url:'/user/order/exportOrder?data='+ JSON.stringify(_data),
            method: 'get',
            responseType: 'blob',
        });
    }
}