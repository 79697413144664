
import request from '../request.js'

export default {
    getDocListByType(_data){
        return request({
            url:'/user/doc/getDocListByType?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    getDocListByCollect(_data){
        return request({
            url:'/user/doc/getDocListByCollect?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    getDocList(_data){
        return request({
            url:'/user/doc/getDocList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    
    // 知识库文档首页搜索
    getDocLists(_data,_html){
        return request({
            url: '/user/doc/getIndexDocList?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },
    getIndexDocList(_data,_html){
        return request({
            url:'/user/doc/getIndexDocList?data='+ JSON.stringify(_data),
            method:'post',
            data: _html
        })
    },
    getDocDetail(_data){
        return request({
            url: '/user/doc/getDocDetail?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    collectDoc(_data){
        return request({
            url: '/user/doc/collectDoc?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 文档分类列表
    docCateList(_data){
        return request({
            url: '/user/doc/docCateList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 文档分类列表
    userDocCategorylist(_data){
        return request({
            url: '/user/userDocCategory/list?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 文档分类添加
    addUserDocumentCategories(_data){
        return request({
            url: '/user/userDocCategory/addUserDocumentCategories?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 文档分类删除
    deleteUserDocumentCategory(_data){
        return request({
            url: '/user/userDocCategory/deleteUserDocumentCategory?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 我的文档列表
    getMyDocList(_data){
        return request({
            url: '/user/doc/getMyDocList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    knowledgeBaseRecommendations(_data){
        return request({
            url:'/user/doc/knowledgeBaseRecommendations?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
}