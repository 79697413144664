export default {
    namespaced: true,
    state: {
        search: JSON.parse(localStorage.getItem('search')) || []
    },
    getters: {
        get_search(state) {
            if(Array.isArray(state.search)) {
                return state.search
            }
            return []
        },
    },
    mutations: {
        putSearch(state, payload) {
            if (payload) {
                // 只保存最后10个
                state.search = Array.from(new Set(state.search.concat([payload]))).slice(-10)
                localStorage.setItem('search', JSON.stringify(Array.from(new Set(state.search))))
            }
        },
        clearSearch(state, payload) {
            localStorage.removeItem('search')
            state.search = []
        }
    },
    actions: {

    }
}
