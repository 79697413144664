import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  { 
    path: "/login", 
    name: 'login',
    meta:{ noToken: true },
    component: () => import('@/views/login/login.vue')
  },
  { 
    path: "/userAgreement", 
    name: 'userAgreement',
    meta:{ noToken: true },
    component: () => import('@/views/login/userAgreement.vue')
  },
  {
    path: "/privacyPolicy", 
    name: 'privacyPolicy',
    meta:{ noToken: true },
    component: () => import('@/views/login/privacyPolicy.vue')
  },
  { 
    path: "/register", 
    name: 'register',
    meta:{ noToken: true },
    component: () => import('@/views/login/register.vue')
  },
  { 
    path: "/password",
    name: 'password',
    meta:{ noToken: true },
    component: () => import('@/views/login/password.vue')
  },
  { 
    path: "/", 
    component: () => import('@/components/navbar.vue'),
    redirect: '/controlBoard',
    name: 'root',
    children: [
      { 
        path: "/controlBoard",
        name: 'controlBoard',
        component: () => import('@/views/service/index.vue'),
        meta:{ titL: ['控制台中心'] , tab:'/controlBoard' },
      },
      { 
        path: "/search", 
        name: 'search',
        component: () => import('@/views/service/search.vue'),
        meta:{ titL: ['知识库文档', '搜索'] , tab:'/controlBoard' },
      },
      { 
        path: "/serviceInfo/:id", 
        name: 'serviceInfo',
        component: () => import('@/views/service/info.vue'),
        meta:{ titL: ['工单列表', '工单详情'] , requiresAuth: true },
      },
      // Bug
      { 
        path: "/bug/list",
        name: 'bug/list',
        component: () => import('@/views/bug/list.vue'),
        meta:{ titL: ['Bug反馈'] , tab:'/bug/list' },
      },
      {
        path: "/bug/create",
        name: 'bug/create',
        component: () => import('@/views/bug/create.vue'),
        meta:{ titL: ['Bug反馈'] , tab:'/bug/list' },
      },
      {
        path: "/bug/in/:id",
        name: 'bug/in',
        component: () => import('@/views/bug/in.vue'),
        meta:{ titL: ['Bug详细'] , tab:'/bug/list' },
      },
      // 技术服务请求
      {
        path: "/tech/list/:type", 
        name: 'tech/list',
        component: () => import('@/views/tech/list.vue'),
        meta:{ titL: ['技术服务请求'] },
      },
      { 
        path: "/submit/tech", 
        name: 'submit/tech',
        component: () => import('@/views/submit/tech.vue'),
        meta:{ titL: ['技术服务请求'] , tab:'/tech/list/2' },
      },
      { 
        path: "/submit/untech", 
        name: 'submit/untech',
        component: () => import('@/views/submit/untech.vue'),
        meta:{ titL: ['非技术服务请求'] , tab:'/tech/list/1' },
      },
      // 资讯列表
      { 
        path: "/news/list", 
        name: 'newsList',
        component: () => import('@/views/news/list.vue'),
        meta:{ titL: ['资讯列表'] , tab:'/news/list' },
      },
      { 
        path: "/news/detail/:id", 
        name: 'newsDetail',
        component: () => import('@/views/news/detail.vue'),
        meta:{ titL: ['公告详情'] , tab:'/news/list' },
      },
      { 
        path: "/news/lists", 
        name: 'newsLists',
        component: () => import('@/views/news/lists.vue'),
        meta:{ titL: ['学习课堂'] , tab:'/news/lists' },
      },
      { 
        path: "/news/details/:id", 
        name: 'newsDetails',
        component: () => import('@/views/news/details.vue'),
        meta:{ titL: ['课堂详情'] , tab:'/news/lists' },
      },
      // 文档
      { 
        path: "/document/my", 
        name: 'documentMy',
        component: () => import('@/views/document/my.vue'),
        meta:{ titL: ['我的文档'] , tab:'/knowledge/index' },
      },
      {
        path: "/document/create",
        name: 'document/create',
        component: () => import('@/views/document/create.vue'),
        meta:{ titL: ['新建文档'] , tab:'/knowledge/index' },
      },
      {
        path: "/document/creates",
        name: 'document/creates',
        component: () => import('@/views/document/creates.vue'),
        meta:{ titL: ['修改文档'] , tab:'/document/manager' },
      },
      {
          path: "/document/manager", 
					name: 'document/manager',
					component: () => import('@/views/document/manager.vue'),
					meta:{  tab:'/document/manager' },
      },
      { 
        path: "/document/type", 
        name: 'documentType',
        component: () => import('@/views/document/type.vue'),
        meta:{ titL: ['分类管理'] , tab:'/knowledge/index' },
      },
      // 知识库
      
      {
        path: "/knowledge/index", 
        name: 'knowledge/index',
        component: () => import('@/views/knowledge/index.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge/index' },
      },
      
      {
        path: "/knowledge/search", 
        name: 'knowledge/search',
        component: () => import('@/views/knowledge/search.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge/index' },
      },
      { 
        path: "/knowledge/docSearch",
        name: 'knowledge/docSearch',
        component: () => import('@/views/knowledge/docSearch.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge/index' },
      },
      { 
        path: "/knowledge/companyDoc",
        name: 'knowledge/companyDoc',
        component: () => import('@/views/knowledge/companyDoc.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge/index' },
      },
      {
        path: "/knowledgeInfo/:id",
        name: 'knowledgeInfo',
        component: () => import('@/views/knowledge/info.vue'),
        meta:{ titL: ['知识库文档', '文档详情'] , tab:'/knowledge/index' },
      },
      { 
        path: "/account", 
        name: 'account',
        component: () => import('@/views/account/index.vue'),
        meta:{ titL: ['账号中心' ] , tab:'/account' },
      },
      {
        path: "/csiInfo/:id", 
        name: 'csiInfo',
        component: () => import('@/views/account/csiInfo.vue'),
        meta:{ titL: ['账号中心', '绑定CSI详情' ] , tab:'/account' },
      },
      { 
        path: "/cgeEmail", 
        name: 'cgeEmail',
        component: () => import('@/views/account/cgeEmail.vue'),
        meta:{ titL: ['账号中心','邮箱换绑' ] , tab:'/account' },
      },
      {
        path: "/cgetel",
        name: 'cgetel',
        component: () => import('@/views/account/cgetel.vue'),
        meta:{ titL: ['账号中心','手机换绑' ] , tab:'/account' },
      },
      { 
        path: "/cgemima",
        name: 'cgemima',
        component: () => import('@/views/account/cgemima.vue'),
        meta:{ titL: ['账号中心','修改密码' ] , tab:'/account' },
      },
      { 
        path: "/news",
        name: 'news',
        component: () => import('@/views/account/news.vue'),
        meta:{ titL: ['通知消息' ] , tab:'/account' },
      },
      { 
        path: "/newsinfo/:id/:type", 
        name: 'newsinfo',
        component: () => import('@/views/account/newsinfo.vue'),
        meta:{ titL: ['通知消息', '消息详情' ] , tab:'/account' },
      },
      { 
        path: "/authenticationCenter", 
        name: 'authenticationCenter',
        component: () => import('@/views/authentication/authenticationCenter.vue'),
        meta:{ titL: ['认证'] , tab:'/authenticationCenter' },
      },
      { 
        path: "/patch", 
        name: 'patch',
        component: () => import('@/views/patch/patch.vue'),
        meta:{ titL: ['补丁/更新程序'] , tab:'/patch' },
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
