import request from '../request.js'

export default {
    // Bug
    bugFeedback(_data){
        return request({
            url: '/user/BugFeedback/bugFeedback?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    newBugFeedback(_data, _html){
        // 新增bug反馈
        return request({
            url: '/user/BugFeedback/newBugFeedback?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },
    productList(_data){
        return request({
            url: '/user/BugFeedback/productList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    moduleList(_data){
        return request({
            url: '/user/BugFeedback/moduleList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    bugdetails(_data){
        return request({
            url: '/user/BugFeedback/bugdetails?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    productListByCsi(_data){
        return request({
            url: '/user/BugFeedback/productListByCsi?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    versionList(_data){
        return request({
            url: '/user/BugFeedback/versionList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    bugstatistics(_data){
        return request({
            url: '/user/BugFeedback/bugstatistics?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    bugCommunicationRecord(_data){
        return request({
            url: '/user/BugFeedback/bugCommunicationRecord?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    BugFeedbackAddBugCommunicationReview(_data, _html){
        // 新增bug沟通反馈
        return request({
            url: '/user/BugFeedback/addBugCommunicationReview?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },
    BugFeedbackRemoveBug(_data){
        // 删除bug
        return request({
            url: '/user/BugFeedback/removeBug?data='+ JSON.stringify(_data),
            method: 'post'
        })
    },
    BugFeedbackRemoveCommunicationRecord(_data){
        // 删除沟通反馈
        return request({
            url: '/user/BugFeedback/removeCommunicationRecord?data='+ JSON.stringify(_data),
            method: 'post'
        })
    },
    engBugFeedbackGetTapdWorkspaceList(_data){
        // tapd项目模块管理列表
        return request({
            url: '/user/BugFeedback/getTapdWorkspaceList?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    //项目模块列表
    engBugFeedbackGetTapdModuleList(_data) {
        return request({
            url: '/user/BugFeedback/getTapdModuleList?data=' + JSON.stringify(_data),
            method: 'get',
        })
    }
}