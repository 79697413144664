<template>
  <div class="kindeditor" >
    <textarea :id="id"  name="content" v-model="outContent" placeholder="请输入"></textarea>
    <input
      @change="selectedFile"
      style="visibility: hidden;height:0;"
      type="file"
      name
      id="inputFile"
    />
  </div>
</template>
 
<script>
import OSS from 'ali-oss'
import '../../node_modules/kindeditor/kindeditor-all.js'
import '../../node_modules/kindeditor/lang/zh-CN.js'
import '../../node_modules/kindeditor/themes/default/default.css'

export default {
  name: 'kindeditor',
  data () {
    return {
      editor: null,
      outContent: this.content
    }
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    minWidth: {
      type: Number,
      default: 100
    },
    minHeight: {
      type: Number,
      default: 100
    },
    items: {
      type: Array,
      default: function () {
        return [
           'undo', 'redo', 'preview', 'code', 'image','media',
           '|', 'justifyleft', 'justifycenter', 'justifyright',
          'justifyfull', 'insertorderedlist', 'insertunorderedlist', 'indent', 'outdent',  '|', 'fullscreen', 
          'formatblock',  'fontsize', '|', 'forecolor', 'hilitecolor', 'bold',
          'italic', 'underline',  'lineheight', '|', 'table', 'hr',  'link', 'unlink', 
        ]
      }
    },
    // items: {
    //   type: Array,
    //   default: function () {
    //     return [
    //       'source', '|', 'undo', 'redo', '|', 'preview', 'print', 'template', 'code', 'cut', 'copy', 'paste',
    //       'plainpaste', 'wordpaste', '|', 'justifyleft', 'justifycenter', 'justifyright',
    //       'justifyfull', 'insertorderedlist', 'insertunorderedlist', 'indent', 'outdent', 'subscript',
    //       'superscript', 'clearhtml', 'quickformat', 'selectall', '|', 'fullscreen', '/',
    //       'formatblock', 'fontname', 'fontsize', '|', 'forecolor', 'hilitecolor', 'bold',
    //       'italic', 'underline', 'strikethrough', 'lineheight', 'removeformat', '|', 'image', 'multiimage',
    //       'flash', 'media', 'insertfile', 'table', 'hr', 'emoticons', 'baidumap', 'pagebreak',
    //       'anchor', 'link', 'unlink', '|', 'about'
    //     ]
    //   }
    // },
    noDisableItems: {
      type: Array,
      default: function () {
        return ['source', 'fullscreen']
      }
    },
    filterMode: {
      type: Boolean,
      default: true
    },
    htmlTags: {
      type: Object,
      default: function () {
        return {
          font: ['color', 'size', 'face', '.background-color'],
          span: ['style'],
          div: ['class', 'align', 'style'],
          table: ['class', 'border', 'cellspacing', 'cellpadding', 'width', 'height', 'align', 'style'],
          'td,th': ['class', 'align', 'valign', 'width', 'height', 'colspan', 'rowspan', 'bgcolor', 'style'],
          a: ['class', 'href', 'target', 'name', 'style'],
          embed: ['src', 'width', 'height', 'type', 'loop', 'autostart', 'quality',
            'style', 'align', 'allowscriptaccess', '/'],
          img: ['src', 'width', 'height', 'border', 'alt', 'title', 'align', 'style', '/'],
          hr: ['class', '/'],
          br: ['/'],
          'p,ol,ul,li,blockquote,h1,h2,h3,h4,h5,h6': ['align', 'style'],
          'tbody,tr,strong,b,sub,sup,em,i,u,strike': []
        }
      }
    },
    wellFormatMode: {
      type: Boolean,
      default: true
    },
    resizeType: {
      type: Number,
      default: 2
    },
    themeType: {
      type: String,
      default: 'default'
    },
    langType: {
      type: String,
      default: 'zh-CN'
    },
    designMode: {
      type: Boolean,
      default: true
    },
    fullscreenMode: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String
    },
    themesPath: {
      type: String
    },
    pluginsPath: {
      type: String,
      default: ''
    },
    langPath: {
      type: String
    },
    minChangeSize: {
      type: Number,
      default: 5
    },
    loadStyleMode: {
      type: Boolean,
      default: true
    },
    urlType: {
      type: String,
      default: ''
    },
    newlineTag: {
      type: String,
      default: 'p'
    },
    pasteType: {
      type: Number,
      default: 2
    },
    dialogAlignType: {
      type: String,
      default: 'page'
    },
    shadowMode: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 811213
    },
    useContextmenu: {
      type: Boolean,
      default: true
    },
    syncType: {
      type: String,
      default: 'form'
    },
    indentChar: {
      type: String,
      default: '\t'
    },
    cssPath: {
      type: [ String, Array ]
    },
    cssData: {
      type: String
    },
    bodyClass: {
      type: String,
      default: 'ke-content'
    },
    colorTable: {
      type: Array
    },
    afterCreate: {
      type: Function
    },
    afterChange: {
      type: Function
    },
    afterTab: {
      type: Function
    },
    afterFocus: {
      type: Function
    },
    afterBlur: {
      type: Function
    },
    afterUpload: {
      type: Function
    },
    uploadJson: {
      type: String
    },
    fileManagerJson: {
      type: Function
    },
    allowPreviewEmoticons: {
      type: Boolean,
      default: true
    },
    // 本地上传
    allowImageUpload: {
      type: Boolean,
      default: false
    },
    allowFlashUpload: {
      type: Boolean,
      default: false
    },
    allowMediaUpload: {
      type: Boolean,
      default: false
    },
    allowFileUpload: {
      type: Boolean,
      default: true
    },
    allowFileManager: {
      type: Boolean,
      default: false
    },
    fontSizeTable: {
      type: Array,
      default: function () {
        return [ '14px', '16px', '18px', '24px', '32px']
      }
    },
    // 0 网络图片 1本地图片
    imageTabIndex: {
      type: Number,
      default: 0
    },
    formatUploadUrl: {
      type: Boolean,
      default: true
    },
    fullscreenShortcut: {
      type: Boolean,
      default: false
    },
    extraFileUploadParams: {
      type: Array,
      default: function () {
        return []
      }
    },
    filePostName: {
      type: String,
      default: 'imgFile'
    },
    fillDescAfterUploadImage: {
      type: Boolean,
      default: false
    },
    afterSelectFile: {
      type: Function
    },
    pagebreakHtml: {
      type: String,
      default: '<hr style=”page-break-after: always;” class=”ke-pagebreak” />'
    },
    // 在线上传
    allowImageRemote: {
      type: Boolean,
      default: false
    },
    autoHeightMode: {
      type: Boolean,
      default: false
    },
    fixToolBar: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: Number
    },
    uploadParams: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    content (val) {
      this.editor && val !== this.outContent && this.editor.html(val)
    },
    outContent (val) {
      this.$emit('update:content', val)
      this.$emit('on-content-change', val)
    }
  },
  mounted () {
    var _this = this
    _this.editor = window.KindEditor.create('#' + this.id, {
      width: _this.width,
      height: _this.height,
      minWidth: _this.minWidth,
      minHeight: _this.minHeight,
      items: _this.items,
      noDisableItems: _this.noDisableItems,
      filterMode: _this.filterMode,
      htmlTags: _this.htmlTags,
      wellFormatMode: _this.wellFormatMode,
      resizeType: _this.resizeType,
      themeType: _this.themeType,
      langType: _this.langType,
      designMode: _this.designMode,
      fullscreenMode: _this.fullscreenMode,
      basePath: _this.basePath,
      themesPath: _this.cssPath,
      pluginsPath: _this.pluginsPath,
      langPath: _this.langPath,
      minChangeSize: _this.minChangeSize,
      loadStyleMode: _this.loadStyleMode,
      urlType: _this.urlType,
      newlineTag: _this.newlineTag,
      pasteType: _this.pasteType,
      dialogAlignType: _this.dialogAlignType,
      shadowMode: _this.shadowMode,
      zIndex: _this.zIndex,
      useContextmenu: _this.useContextmenu,
      syncType: _this.syncType,
      indentChar: _this.indentChar,
      cssPath: _this.cssPath,
      cssData: _this.cssData,
      bodyClass: _this.bodyClass,
      colorTable: _this.colorTable,
      afterCreate: _this.afterCreate,
      afterChange: function () {
        _this.afterChange
        _this.outContent = this.html()
      },
      afterTab: _this.afterTab,
      afterFocus: _this.afterFocus,
      afterBlur: _this.afterBlur,
      afterUpload: _this.afterUpload,
      uploadJson: _this.uploadJson,
      fileManagerJson: _this.fileManagerJson,
      allowPreviewEmoticons: _this.allowPreviewEmoticons,
      allowImageUpload: _this.allowImageUpload,
      allowFlashUpload: _this.allowFlashUpload,
      allowMediaUpload: _this.allowMediaUpload,
      allowFileUpload: _this.allowFileUpload,
      allowFileManager: _this.allowFileManager,
      fontSizeTable: _this.fontSizeTable,
      imageTabIndex: _this.imageTabIndex,
      formatUploadUrl: _this.formatUploadUrl,
      fullscreenShortcut: _this.fullscreenShortcut,
      extraFileUploadParams: _this.extraFileUploadParams,
      filePostName: _this.filePostName,
      fillDescAfterUploadImage: _this.fillDescAfterUploadImage,
      afterSelectFile: _this.afterSelectFile,
      pagebreakHtml: _this.pagebreakHtml,
      allowImageRemote: _this.allowImageRemote,
      autoHeightMode: _this.autoHeightMode,
      fixToolBar: _this.fixToolBar,
      tabIndex: _this.tabIndex
    })
    // 添加点击图片回调函数
    _this.editor.clickToolbar("image", () => {
      // 禁用自带的图片弹窗
      this.editor.hideDialog();
      // 打开文件
      this.handleOpenFile();
    });
  },
  methods:{
    // 打开文件
    handleOpenFile() {
      let input = window.document.getElementById("inputFile");
      // 解决同一个文件不能监听的问题
      input.addEventListener(
        "click",
        function() {
          this.value = "";
        },
        false
      );
      // 点击input
      input.click();
    },
    // // 图片上传
    // async  selectedFile($event) {
    //   const file = $event.target.files[0];
    //   // console.log($event,file,this.file2Base64(file,res=>{console.log(res)}))
    //   this.file2Base64(file,res=>{
    //     this.$emit('base64Flie',res)
    //   })
    // },

    // 图片上传
    async selectedFile($event) {
      const content = $event.target.files[0];
      try {
        let form = new FormData();
        form.append('multipartFile', content, content.name);
        form.append('type', this.uploadParams.type)
        this.uploadParams.id && form.append('id', this.uploadParams.id)
        const res = await this.$api.base.ossUploadList(form)
        this.file2Base64(content,res=>{
            this.$emit('base64Html',res)
        }, res.path)
      } catch (e) {
        console.log(e)
        if (e.code === 'ConnectionTimeoutError') {
            content.onError('视频上传超时')
            throw "视频上传超时!";
        } else {
            content.onError('视频上传失败')
        }
      }
    },
    // async selectedFile($event) {
    //   let that = this
    //   const content = $event.target.files[0];
    //   console.log('上传文件',content)
    //   uploadOssGetToken().then(data => {
    //     console.log('获取token',data)
    //     let videoName = Date.parse(new Date()) / 1000 + content.name;
    //         let client = new OSS({
    //             region: 'oss-cn-beijing',
    //             secure: true,
    //             accessKeyId: data.ossAccessKeyId,
    //             accessKeySecret: data.accessKeySecret,
    //             stsToken: data.securityToken,
    //             endpoint: "oss-cn-beijing.aliyuncs.com",
    //             bucket: 'bucket-mvs'
    //         });
    //         try {
    //             console.log('文件', content)
    //             //上传
    //             client.multipartUpload(videoName, content, {}).then(result=>{
    //               console.log(result, "ddd")
    //               that.file2Base64(content,res=>{
    //                 that.$emit('base64Html',res)
    //               },result.res.requestUrls[0].split('?uploadId=')[0])
    //             })
                
    //         } catch (e) {
    //             console.log('失败', e)
    //             if (e.code === 'ConnectionTimeoutError') {
    //                 content.onError('视频上传超时')
    //                 throw "视频上传超时!";
    //             } else {
    //                 content.onError('视频上传失败')
    //             }
    //         }
    //   });
    // },


    file2Base64(file, cb,url){
      // let url = URL.createObjectURL(file);
      console.log('file',file)
      let data = url
      if(file.type=='image/jpeg'||file.type=='image/png'||file.type=='image/jpg'){
        data = `<img width="300px" src="${url}" alt="">`
      }
      console.log('file',data)
      // else if(file.type=='video/mp4'){
      //   data = `<video src="${url}"></video>`
      // }else {
      //   data = `<a href="${url}"></a>`
      // }
      let reader = new FileReader();
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        cb(data);
      };
      reader.readAsDataURL(file);
    }
  }
}
</script>