import request from '../request.js'

export default {
    //登录接口
    userlogin(_data){
        return request({
            url:'/user/login/userlogin?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 注册
    registerUser(_data){
        return request({
            url:'/user/login/registerUser?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 验证码
    getAuthCode(_data){
        return request({
            url: '/user/login/getAuthCode?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 用户协议
    getUserAgreement(_data){
        return request({
            url: '/user/login/getUserAgreement?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 隐私政策
    getPrivacyPolicy(_data){
        return request({
            url: '/user/login/getPrivacyPolicy?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
}