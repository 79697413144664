import api from '@/utils/api.js'
import utils from '@/utils/utils.js'
// 搜索总线
export default {
    namespaced: true,
    state: {
        pending: false,
        doc: {
            param: {
                docTitle: '',
                pageNumber: 1,
                pageSize: 4,
            },
            list: [],
            total: 0,
        },
        announ: {
            param: {
                type: '',
                title: '',
                pageNumber: 1,
                pageSize: 4,
            },
            list: [],
            total: 0,
        },
        request: {
            param: {
                problemProfile: '',
                pageNumber: 1,
                pageSize: 4,
            },
            list: [],
            total: 0,
        }
    },
    getters: {
        getTotal(state) {
            return {
                sum: state.doc.total + state.announ.total + state.request.total,
                doc: state.doc.total,
                announ: state.announ.total,
                request: state.request.total,
            }
        },
        getDoc(state) {
            return state.doc.list
        },
        getAnnoun(state) {
            return state.announ.list
        },
        getRequest(state) {
            return state.request.list
        },
        getPending(state) {
            return state.pending
        }
    },
    mutations: {
        setDoc(state, payload) {
            utils.html2Text(payload.records, 'docContent')
            utils.highLightText(payload.records, state.doc.param.docTitle, 'docTitle')
            utils.highLightText(payload.records, state.doc.param.docTitle, 'docContent')
            state.doc.list = payload.records
            state.doc.total = payload.total
        },
        setAnnoun(state, payload) {
            utils.highLightText(payload.records, state.announ.param.title, 'title')
            state.announ.list = payload.records
            state.announ.total = payload.total
        },
        setRequest(state, payload) {
            utils.highLightText(payload.records, state.request.param.problemProfile, 'problemProfile')
            state.request.list = payload.records
            state.request.total = payload.total
        },
        setPending(state, payload) {
            state.pending = payload
        }
    },
    actions: {
        // 集合搜索线
        async multiActionLine({ commit, state, getters }, payload) {
            try {
                if (state.pending) {
                    return Promise.resolve()
                }
                commit('setPending', true)
                state.doc.param.docTitle = payload
                state.announ.param.title = payload
                state.request.param.problemProfile = payload
                console.log('111state.docstate.docstate.docstate.doc',state.doc)
                let param = JSON.parse(JSON.stringify(state.doc.param))
				delete param.docTitle
                // 从第一次调用，开始请求加载总线，直到请求结果返回，下一次请求才起作用
                const [doc, announ, request] = await Promise.all([
                    api.know.getIndexDocList(param,{docTitle:state.doc.param.docTitle}),
                    api.base.announcementDynamics(state.announ.param),
                    api.tech.serviceRerviceEsRequest(state.request.param)
                ])
                commit('setDoc', doc)
                commit('setAnnoun', announ)
                commit('setRequest', request)
                commit('setPending', false)
                return Promise.resolve()
            } catch (error) {
                commit('setPending', false)
                return Promise.reject(error)
            }
        },
    }
}
