import api from '@/utils/api.js'
export default {
    namespaced: true,
    state: {
        csiList: undefined, // 缓存csi编号
        hardwarePlatform: undefined, // 缓存硬件平台
        language: undefined, // 缓存操作系统语言
        deploy: undefined, // 缓存操作系统语言
    },
    getters: {
        getCsiList(state) {
            return state.csiList??[]
        },
        getHardwarePlatform(state) {
            return state.hardwarePlatform??[]
        },
        getLanguage(state) {
            return state.language??[]
        },
        getDeploy(state) {
            return state.deploy??[]
        }
    },
    mutations: {
        setCsiList(state, payload) {
            state.csiList = payload
        },
        setHardwarePlatform(state, payload) {
            state.hardwarePlatform = payload
        },
        setLanguage(state, payload) {
            state.language = payload
        },
        setDeploy(state, payload) {
            state.deploy = payload
        },
        clearCache(state) {
            for (const key in state) {
                state[key] = undefined
            }
        }
    },
    actions: {
        async getCsiList({ commit, state, getters }) {
            try {
                const result = state.csiList??(await api.tech.getOrderCsiListDto())
                commit('setCsiList', result)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async getHardwarePlatformList({ commit, state, getters }) {
            try {
                const result = state.hardwarePlatform??(await api.base.dictDataList({ dictType: 'system_yingjian' })).records
                commit('setHardwarePlatform', result)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async getLanguage({ commit, state, getters }) {
            try {
                const result = state.language??(await api.base.dictDataList({ dictType: 'system_yuyan' })).records
                commit('setLanguage', result)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async getDeploy({ commit, state, getters }) {
            try {
                const result = state.deploy??(await api.base.dictDataList({ dictType: 'system_arrange' })).records
                commit('setDeploy', result)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        },
    }
}
