import request from '../request.js'

export default {
    // 软件包
    softwarePatch(_data){
        return request({
            url: '/user/PatchUpdate/softwarePatch?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    check(_data){
        return request({
            url: '/user/PatchUpdate/check?data='+ JSON.stringify(_data),
            method:'get',
        })
    }
}