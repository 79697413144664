<template>
  <el-config-provider :locale="locale">
    <router-view v-if="routerAlive"></router-view>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      locale : zhCn,
      routerAlive: true
    }
  },
  provide () {
    return {
      routerRefresh: this.routerRefresh
    }
  },
  methods: {
    routerRefresh () {
      this.routerAlive = false;
      this.$nextTick(() => {
        this.routerAlive = true;
      });
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1080px;
}
</style>
