
import request from '../request.js'

export default {
    userCollectionCategory(_data){
        return request({
            url:'/user/system/userCollectionCategory?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 查询认证
    getsoftauthentication(_data){
        return request({
            url: '/user/authenticationinfo/getsoftauthentication?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 查询编辑部门
    addeditdepartment(_data){
        return request({
            url: '/user/system/addeditdepartment?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // cau分配给其他人
    distributioncau(_data){
        return request({
            url: '/user/system/distributioncau?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 人员列表
    systemuserlist(_data){
        return request({
            url: '/user/system/systemuserlist?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 部门列表
    systemdepartmentlist(_data){
        return request({
            url: '/user/system/systemdepartmentlist?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 删除部门
    delldepartment(_data){
        return request({
            url: '/user/system/delldepartment?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 审核通过
    auditorbangding(_data){
        return request({
            url: '/user/system/auditorbangding?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 人员信息管理编辑
    addeditdsystemuserinfo(_data){
        return request({
            url: '/user/system/addeditdsystemuserinfo?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 解除绑定
    Unbindbangding(_data){
        return request({
            url: '/user/system/Unbindbangding?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 根据csi获取部门列表
    getDepartmentListByCsiId(_data){
        return request({
            url: '/user/order/getDepartmentListByCsiId?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 部门人员列表
    departmentUserlist(_data){
        return request({
            url: '/user/system/departmentUserlist?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 修改消息设置
    editMessage(_data){
        return request({
            url: '/user/NotificationMessage/editMessage?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 验证原邮箱号
    verifyTheOriginalEmail(_data){
        return request({
            url: '/user/system/verifyTheOriginalEmail?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 绑定新邮箱号
    bindANewEmail(_data){
        return request({
            url: '/user/system/bindANewEmail?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 撤回审核
    withdrawalReview(_data){
        return request({
            url: '/user/doc/withdrawalReview?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
}