import request from '../request.js'

export default {
    // 我的文档
    myDocument(_data){
        return request({
            url:'/user/mydocument/myDocument?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 公司文档分类
    companyDocument(_data){
        return request({
            url:'/user/companydocument/companyDocument?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 公司文档类型添加
    addCompanyDocumentCategories(_data){
        return request({
            url:'/user/companydocument/addCompanyDocumentCategories?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 公司文档类型删除
    deleteACompanyDocumentCategory(_data){
        return request({
            url:'/user/companydocument/deleteACompanyDocumentCategory?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 文档审核
    documentReview(_data){
        return request({
            url:'/user/DocumentReview/documentReview?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 公司列表
    userCompanyLists(_data){
        return request({
            url:'/user/companydocument/userCompanyLists?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 用户管理的公司列表
    userCoeCompanyLists(_data){
        return request({
            url:'/user/companydocument/userCoeCompanyLists?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    getDocStatusCount(_data){
        return request({
            url: '/user/doc/getDocStatusCount?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    // 添加我的文档
    insertDoc(_data, _html){
        return request({
            url: '/user/doc/insertDoc?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },
    // 编辑我的文档
    insertDocs(_data, _html){
        return request({
            url: '/user/DocumentReview/coeEditDoc?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },
    // 删除文档
    removeDoc(_data){
        return request({
            url: '/user/doc/removeDoc?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 收藏文档
    insertCollectCategory(_data){
        return request({
            url: '/user/doc/insertCollectCategory?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 文档审核
    checkDoc(_data){
        return request({
            url: '/user/DocumentReview/checkDoc?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 文档反馈
    subDocFeedback(_data, _html) {
        return request({
            url: '/user/doc/subDocFeedback?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },    
}