import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import installElementPlus from './plugins/element'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/style.scss'
import api from './utils/api'
import utils from './utils/utils.js'
import VueClipboard from 'vue-clipboard2'


// import kindeditor from '@/components/kindeditor.vue'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import kindeditor from '@/components/kindeditor.vue'

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});



// ***************************************************************************
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
// codemirror 编辑器的相关资源
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';
VMdEditor.Codemirror = Codemirror;
// *********************************************************************************

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

// 页面标题对应router里自定义的title
router.beforeEach((to,from,next)=>{
    // console.log(store.state.token)
    
    if(store.state.token || to.meta?.noToken ){
      next()
    }else{
      next({path: '/login', query: {redirect: to.path}})
    }
})

const app = createApp(App)

app.config.globalProperties.utils = utils
app.config.globalProperties.$api = api

import { Boot } from '@wangeditor/editor'
import markdownModule from '@wangeditor/plugin-md'
// installElementPlus(app)
app.use(ElementPlus)
app.use(store).use(router).use(VMdPreview).use(VMdEditor).use(VueClipboard).component('kindeditor',kindeditor).mount('#app')
 