import request from '../request.js'

export default {
    // 学习课堂
    learningClassroom(_data){
        return request({
            url: '/user/learningclassroom/learningClassroom?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 学习课堂-详情
    learningclassroomDetail(_data){
        return request({
            url: '/user/learningclassroom/detail?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 公告接口
    announcementDynamics(_data){
        return request({
            url: '/user/usernew/announcementDynamics?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 公告详情
    initnewDetailDto(_data){
        return request({
            url: '/user/usernew/initnewDetailDto?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 获取系统数据
    dictDataList(_data){
        return request({
            url: '/system/sysDictionary/dictDataList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 获取系统数据
    operatingSystemList(_data){
        return request({
            url: '/system/sysDictionary/operatingSystemList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },
    // 获取预设模版根据模块id
    searchQuestionTemplate(_data){
        return request({
            url: '/user/order/searchQuestionTemplate?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    ossUploadList(_data){
        return request({
            url:'/user/upload/ossUploadList',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: _data,
            method:'post',
        })
    }
}